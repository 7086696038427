import {
  Component,
  DestroyRef,
  Inject,
  inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Router,
  RouterOutlet,
} from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { ToastModule } from 'primeng/toast';
import {
  switchMap,
  take,
} from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { GlobalRoutes } from './core/enums/module-routes.enum';
import { AuthFacade } from './modules/auth/store/auth.facade';
import { Profile } from './modules/auth/types/profile.type';

@Component({
  standalone: true,
  selector: 'll-root',
  styleUrl: './app.component.scss',
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    ToastModule,
  ],
})
export class AppComponent implements OnInit {
  protected isBrowser!: boolean;
  protected title: string = 'pwc-neom-frontend';

  private readonly destroy: DestroyRef = inject(DestroyRef);

  public constructor(
    private readonly router: Router,
    private readonly authFacade: AuthFacade,
    private readonly userIdleService: UserIdleService,
    @Inject(PLATFORM_ID) protected readonly platformId: Record<string, unknown>,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public ngOnInit(): void {
    this.userIdleService.onTimerStart()
      .pipe(
        switchMap(() => this.authFacade.selectProfile$),
        take(1),
        takeUntilDestroyed(this.destroy),
      )
      .subscribe((profile: Profile | null) => {
        this.userIdleService.stopWatching();

        localStorage.setItem('email', profile?.email as string);

        this.router.navigate([
          GlobalRoutes.Root,
          GlobalRoutes.Expired,
        ]);
      });
  }
}
