import {
  APP_INITIALIZER,
  FactoryProvider,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@Environment';

const defaultLocaleInitializer = (translateService: TranslateService) => {
  return () => translateService.setDefaultLang(environment.defaultLocale);
};

export const DEFAULT_LOCALE_PROVIDER: FactoryProvider = {
  multi: true,
  provide: APP_INITIALIZER,
  useFactory: defaultLocaleInitializer,
  deps: [TranslateService],
};
