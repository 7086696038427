import { ClassProvider } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import {
  from,
  map,
  Observable,
} from 'rxjs';

import { SupportedLocales } from '../enums/supported-locales.enum';

class CustomTranslateLoader implements TranslateLoader {
  public getTranslation(locale: SupportedLocales): Observable<object> {
    return from(import(`../../../assets/i18n/${locale}.json`))
      .pipe(
        map((translationModule: { default: object }) => translationModule.default),
      );
  }
}

export const TRANSLATE_LOADER_PROVIDER: ClassProvider = {
  provide: TranslateLoader,
  useClass: CustomTranslateLoader,
};
