import { bootstrapApplication } from '@angular/platform-browser';
import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/angular';
import { isDevMode } from '@angular/core';

import { environment } from '@Environment';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

init({
  tracesSampleRate: 1.0,
  dsn: environment.sentryDsn,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: isDevMode() ? 'development' : 'production',
  integrations: [
    replayIntegration(),
    browserTracingIntegration(),
  ],
  tracePropagationTargets: [
    'localhost',
    'https://pwc-neom-fe-stg.ideatolife.me',
  ],
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
