import {
  inject,
  PLATFORM_ID,
} from '@angular/core';
import { ResolveFn } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import {
  filter,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { AuthFacade } from '@App/modules/auth/store/auth.facade';
import { Profile } from '@App/modules/auth/types/profile.type';

export const appResolver: ResolveFn<boolean> = (): Observable<boolean> => {
  const authFacade: AuthFacade = inject(AuthFacade);
  const userIdleService: UserIdleService = inject(UserIdleService);
  const isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  return authFacade.selectProfile$
    .pipe(
      switchMap((profile: Profile | null) => {
        if (profile) {
          return of(profile);
        }

        return authFacade.getProfile();
      }),
      switchMap(() => authFacade.selectPending$),
      filter((pending: boolean) => !pending),
      tap(() => {
        if (isBrowser) {
          userIdleService.startWatching();
        }
      }),
      take(1),
    );
};
