import { ActionReducerMap } from '@ngrx/store';

import { reducer as systemReducer } from '@App/core/store/system/system.feature';
import { State } from '@App/core/types/store.type';
import { reducer as authReducer } from '@App/modules/auth/store/auth.feature';

export const initialState: State = {
};

export const reducers: ActionReducerMap<State> = {
  auth: authReducer,
  system: systemReducer,
};
