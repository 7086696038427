import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  inject,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import {
  catchError,
  Observable,
  of,
} from 'rxjs';

import { AuthRoutes } from '@App/modules/auth/enums/auth-routes.enum';

import {
  GlobalRoutes,
  ModuleRoutes,
} from '../enums/module-routes.enum';
import { Severities } from '../enums/severities.enum';

export const httpInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  if (isBrowser) {
    const skip: boolean = !!request.headers.get('skip');
    const newRequest: HttpRequest<unknown> = request.clone({
      withCredentials: true,
      headers: request.headers.delete('skip'),
    });

    if (skip) {
      return next(newRequest);
    }

    const router: Router = inject(Router);
    const messageService: MessageService = inject(MessageService);
    const translateService: TranslateService = inject(TranslateService);

    return next(newRequest)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            router.navigate([
              GlobalRoutes.Root,
              ModuleRoutes.Auth,
              AuthRoutes.Login,
            ]);
          }

          if (error.error.message) {
            messageService.add({
              severity: Severities.Error,
              summary: translateService.instant('SEVERITIES.ERROR'),
              detail: translateService.instant(`ERRORS.${error.error.message}`),
            });
          }

          throw error;
        }),
      );
  }

  return of(new HttpResponse());
};
