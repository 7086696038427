import {
  APP_INITIALIZER,
  ErrorHandler,
  FactoryProvider,
  ValueProvider,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  createErrorHandler,
  TraceService,
} from '@sentry/angular';

const SENTRY_ERROR_PROVIDER: ValueProvider = {
  provide: ErrorHandler,
  useValue: createErrorHandler(),
};

const SENTRY_ROUTER_PROVIDER: FactoryProvider = {
  deps: [Router],
  provide: TraceService,
  useFactory: () => () => {},
};

const SENTRY_INITIALIZER_PROVIDER: FactoryProvider = {
  multi: true,
  deps: [TraceService],
  provide: APP_INITIALIZER,
  useFactory: () => () => {},
};

export const SENTRY_PROVIDERS: [ValueProvider, FactoryProvider, FactoryProvider] = [
  SENTRY_ERROR_PROVIDER,
  SENTRY_ROUTER_PROVIDER,
  SENTRY_INITIALIZER_PROVIDER,
];
