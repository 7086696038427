import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  CreateEffectMetadata,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';

import { SystemRepository } from '@App/core/repositories/system.repository';
import { Country } from '@App/core/types/country.type';
import { Role } from '@App/core/types/role.type';

import { SystemActions } from './system.actions';

@Injectable()
export class SystemEffects {
  public constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly systemRepository: SystemRepository,
  ) {
  }

  public getRoles$: CreateEffectMetadata = createEffect(() => (
    this.actions$.pipe(
      ofType(SystemActions.getRoles),
      switchMap((): Observable<Role[]> => this.systemRepository.getRoles()),
      tap((): void => {
        this.store.dispatch(SystemActions.setPending({ pending: false }));
      }),
      catchError((error: HttpErrorResponse): never => {
        this.store.dispatch(SystemActions.setError({ error: true }));
        this.store.dispatch(SystemActions.setPending({ pending: false }));

        throw error;
      }),
      map((roles: Role[]) => SystemActions.getRolesSuccess({ roles })),
    )
  ));

  public getCountries$: CreateEffectMetadata = createEffect(() => (
    this.actions$.pipe(
      ofType(SystemActions.getCountries),
      switchMap((): Observable<Country[]> => this.systemRepository.getCountries()),
      tap((): void => {
        this.store.dispatch(SystemActions.setPending({ pending: false }));
      }),
      catchError((error: HttpErrorResponse): never => {
        this.store.dispatch(SystemActions.setError({ error: true }));
        this.store.dispatch(SystemActions.setPending({ pending: false }));

        throw error;
      }),
      map((countries: Country[]) => SystemActions.getCountriesSuccess({ countries })),
    )
  ));
}
